const adminsReducer = (state = { adminsList: [], selectedAdmin: null, adminLogs: [] }, action) => {
  switch (action.type) {
    case "GET_ADMINS_LIST_SUCCESS":
      return { ...state, adminsList: action.payload }
    case "GET_ADMIN_DETAILS_SUCCESS":
      return { ...state, selectedAdmin: action.payload }
    case "GET_ADMIN_LOGS_SUCCESS":
      return { ...state, adminLogs: action.payload }
    case "QR_TOKEN_REFRESH_SUCCESS":
      return { ...state, qrRefresh: action.payload }
    case "USERS_DATA_DELETE_SUCCESS":
      return { ...state, usersDeleted: action.payload }
    default:
      return state
  }
}

export default adminsReducer
