import axios from "../../../utility/api"

const userDetails = (item = false) => {
  return {
    id: item ? item.id : 0,
    enabled: item ? item.enabled : 0,
    name: item ? item.name : "",
    number: item ? item.number : "",
    card_number: item ? item.card_number : "",
    ccms: (item && item.ccms) ? item.ccms : "",
    name_ccms: (item && item.name_ccms) ? item.name_ccms : "",
    phone_number: (item && item.phone_number) ? item.phone_number : "",
    numberPrefix: (item && item.numberPrefix) ? item.numberPrefix : "",
    login: item ? item.login : "",
    building: item ? item.building : "",
    floor: item ? item.floor : "",
    project: item ? item.project : "",
    invoice: item ? item.invoice : "",
    nif: item ? item.nif : "",
    email: item ? item.email : "",
    card: item ? item.card : "",
    pin: item ? item.pin : "",
    obs: (item && item.obs) ? item.obs : "",
    status: "active",
    validUntil: (item && item.valid_until) ? item.valid_until : "",
    userType: (item && item.locker_user_type) ? item.locker_user_type.name : "",
    lockers: item && item.lockers.map((item2, i2) => {
      return item2
    }),
    assets: item ? item.assets : [],
    groups: item ? item.groups : [],
    schedules: item ? item.schedules : []
  }
}

const userKpis = (item = false) => {
  return {
    recentActions: item ? item.recentActions : [],
    reports: item ? item.reports : [],
    timeSpentWithDoorOpen: {
      last1Year: item ? item.timeSpentWithDoorOpen.last1Year : 0,
      last30Days: item ? item.timeSpentWithDoorOpen.last30Days : 0,
    },
    doorOpenings: {
      last1Year: item ? item.doorOpenings.last1Year : 0,
      last15Days: item ? item.doorOpenings.last15Days : 0,
      last30Days: item ? item.doorOpenings.last30Days : 0,
      total: item ? item.doorOpenings.total : 0,
    },
    doorAlerts: {
      last1Year: item ? item.doorAlerts.last1Year : 0,
      last15Days: item ? item.doorAlerts.last15Days : 0,
      last30Days: item ? item.doorAlerts.last30Days : 0,
      total: item ? item.doorAlerts.total : 0,
    },
  }
}

export const getUsers = (token, reset = false) => (dispatch) =>
  new Promise(function (resolve, reject) {

    if (reset) {
      dispatch({
        type: "GET_USERS_LIST_SUCCESS",
        payload: false
      })
    }

    axios.defaults.headers.common = { 'Authorization': 'Bearer ' + token }
    axios.get('api/julian/users')
      .then(response => {
        if (response.data.ResultCode === 1) {
          let userList = response.data.Data.map((item, i) => {
            return userDetails(item)
          })
          dispatch({
            type: "GET_USERS_LIST_SUCCESS",
            payload: userList
          })
          resolve()
        } else {
          reject()
        }
      })
      .catch(err => {
        reject()
      })
  })

export const getUserDetails = (token, id, reset = false) => (dispatch) =>
  new Promise(function (resolve, reject) {
    if (reset) {
      dispatch({
        type: "GET_USER_DETAILS_SUCCESS",
        payload: false
      });
    }

    if (id === "new") {
      dispatch({
        type: "GET_USER_DETAILS_SUCCESS",
        payload: userDetails()
      });
      return;
    }
    axios.defaults.headers.common = { 'Authorization': 'Bearer ' + token }
    axios.get('api/julian/user/' + id).then(res => {
      if (res.data.ResultCode === 1) {
        dispatch({
          type: "GET_USER_DETAILS_SUCCESS",
          payload: userDetails(res.data.Data)
        });
      } else {
        reject()
        throw (res);
      }
    }).catch(err => {
      reject()
      console.log(err)
    })
  })

export const setUserDetails = (token, user) => (dispatch) =>
  new Promise(function (resolve, reject) {
    axios.defaults.headers.common = { 'Authorization': 'Bearer ' + token }
    if (user.id === 0) {
      axios.post('api/julian/user', {
        name: user.name,
        //number: user.number,
        //cardNumber: user.card_number,
        login: user.login,
        numberPrefix: user.numberPrefix,
        //ccms: user.ccms,
        //card: user.card,
        //nameCcms: user.name_ccms,
        //validUntil: user.valid_until,
        //building: user.building,
        //floor: user.floor,
        //company: user.project,
        email: user.email,
        nif: user.nif,
        //lockerUserType: user.lockerUserType,
        obs: user.obs,
        //groupIds: user.groupIds
      })
        .then(res => {
          console.log(res)
          if (res.data.ResultCode === 1) {
            dispatch({
              type: "GET_USER_DETAILS_SUCCESS",
              payload: userDetails(res.data.Data)
            });
            resolve(res)
          } else {
            reject()
          }
        })
        .catch(err => {
          console.log(err)
          reject()
        })
    } else {
      axios.put('api/julian/user', {
        id: user.id,
        name: user.name,
        //number: user.number,
        //cardNumber: user.card_number,
        login: user.login,
        numberPrefix: user.numberPrefix,
        //ccms: user.ccms,
        //card: user.card,
        //nameCcms: user.name_ccms,
        //validUntil: user.valid_until,
        //building: user.building,
        //floor: user.floor,
        //company: user.project,
        email: user.email,
        nif: user.nif,
        //lockerUserType: user.lockerUserType,
        obs: user.obs
      })
        .then(res => {
          console.log(res)
          if (res.data.ResultCode === 1) {
            dispatch({
              type: "GET_USER_DETAILS_SUCCESS",
              payload: userDetails(res.data.Data)
            });
            resolve(res)
          } else {
            reject()
          }
        })
        .catch(err => {
          console.log(err)
          reject()
        })
    }
  })


export const setUserCard = (token, user) => (dispatch) =>
  new Promise(function (resolve, reject) {
    if (user.id === 0) {
      reject()
      throw ("error");
    } else {
      axios.defaults.headers.common = { 'Authorization': 'Bearer ' + token }
      axios.put('api/julian/user', {
        id: user.id,
        card: user.card ? user.card : 'no-card-number'
      })
        .then(res => {
          if (res.data.ResultCode === 1) {
            dispatch({
              type: "GET_USER_DETAILS_SUCCESS",
              payload: userDetails(res.data.Data)
            });
            resolve()
          } else {
            reject()
            throw (res);
          }
        })
        .catch(err => {
          reject()
          console.log(err)
        })
    }
  })

export const setUserPin = (token, user, pin) => (dispatch) =>
  new Promise(function (resolve, reject) {
    axios.defaults.headers.common = { 'Authorization': 'Bearer ' + token }
    axios.put('api/julian/user', {
      id: user.id,
      pin: pin
    })
      .then(res => {
        if (res.data.ResultCode === 1) {
          dispatch({
            type: "GET_USER_DETAILS_SUCCESS",
            payload: userDetails(res.data.Data)
          });
          resolve()
        } else {
          reject()
          throw (res);
        }
      })
      .catch(err => {
        reject()
        console.log(err)
      })

  })

export const getLockers = (token, reset = false) => (dispatch) =>
  new Promise(function (resolve, reject) {
    if (reset) {
      dispatch({
        type: "GET_USER_PLACES_LOCKERS",
        payload: false
      })
    }

    axios.defaults.headers.common = { 'Authorization': 'Bearer ' + token }
    axios.get('api/julian/lockers')
      .then(res => {
        if (res.data.ResultCode === 1) {
          let lockers = res.data.Data && Object.keys(res.data.Data).reduce(function (r, a) {
            r[a] = res.data.Data[a]
            return r
          }, {})

          dispatch({
            type: "GET_USER_PLACES_LOCKERS",
            payload: lockers
          })
          resolve()
        } else {
          reject()
          throw (res)
        }
      }).catch(err => {
        console.log(err)
        reject()
      })
  })

export const getUserKpis = (token, id, reset = false) => (dispatch) =>
  new Promise(function (resolve, reject) {
    if (reset) {
      dispatch({
        type: "GET_USER_KPIS_SUCCESS",
        payload: false
      });
    }

    axios.defaults.headers.common = { 'Authorization': 'Bearer ' + token }
    axios.get('api/julian/dashboard/user/' + id).then(res => {
      if (res.data.ResultCode === 1) {
        dispatch({
          type: "GET_USER_KPIS_SUCCESS",
          payload: userKpis(res.data.Data)
        });
        resolve()
      } else {
        reject()
        throw (res)
      }
    }).catch(err => {
      reject()
      console.log(err)
    })
  })

export const deleteUser = (token, user) => (dispatch) =>
  new Promise(function (resolve, reject) {
    axios.defaults.headers.common = { 'Authorization': 'Bearer ' + token }
    axios.delete('api/julian/user/' + user.id).then(response => {
      if (response.data.ResultCode === 1) {
        // console.log('redux', response.data)
        // let userList = response.data.Data.map((item, i) => {
        //   return userDetails(item)
        // })
        // dispatch({
        //   type: "GET_USERS_LIST_SUCCESS",
        //   payload: userList
        // })
        resolve()
      } else {
        return reject(response.data)
      }
    }).catch(err => {
      console.log(err)
      return reject(err)
    })
  })

export const enableUser = (token, user, enabled) => (dispatch) =>
  new Promise(function (resolve, reject) {
    axios.defaults.headers.common = { 'Authorization': 'Bearer ' + token }
    axios.post('api/julian/user/enabled', {
      id: user.id,
      enabled: enabled,
    }).then(response => {
      if (response.data.ResultCode === 1) {
        resolve()
      } else {
        return reject(response.data)
      }
    }).catch(err => {
      console.log(err)
      return reject(err)
    })
  })

export const addGroupsToUser = (token, groupIds, userId) => (dispatch) =>
  new Promise(function (resolve, reject) {
    var ids = ''
    for (var i = 0; i < groupIds.length; i++) {
      if (ids === '') ids += groupIds[i].id
      else ids += ',' + groupIds[i].id
    }

    axios.defaults.headers.common = { 'Authorization': 'Bearer ' + token }
    axios.get('api/julian/authorizeGroups?userId=' + userId + '&userGroupIds=' + ids).then(response => {
      if (response.data.ResultCode === 1) {
        dispatch({
          type: "GET_USER_DETAILS_SUCCESS",
          payload: userDetails(response.data.Data)
        })
        resolve()
      } else {
        reject()
      }
    }).catch(err => {
      reject()
    })
  })

export const removeGroupsFromUser = (token, groupIds, userId) => (dispatch) =>
  new Promise(function (resolve, reject) {
    var ids = ''
    for (var i = 0; i < groupIds.length; i++) {
      if (ids === '') ids += groupIds[i].id
      else ids += ',' + groupIds[i].id
    }

    axios.defaults.headers.common = { 'Authorization': 'Bearer ' + token }
    axios.get('api/julian/unauthorizeGroups?userId=' + userId + '&userGroupIds=' + ids).then(response => {
      if (response.data.ResultCode === 1) {
        dispatch({
          type: "GET_USER_DETAILS_SUCCESS",
          payload: userDetails(response.data.Data)
        })
        resolve()
      } else {
        reject()
      }
    }).catch(err => {
      reject()
    })
  })

export const addSchedulesToUser = (token, schedules, userId) => (dispatch) =>
  new Promise(function (resolve, reject) {
    var ids = ''
    for (var i = 0; i < schedules.length; i++) {
      if (ids === '') ids += schedules[i].id
      else ids += ',' + schedules[i].id
    }

    axios.defaults.headers.common = { 'Authorization': 'Bearer ' + token }
    axios.get('api/julian/addSchedules?lockerUserId=' + userId + '&scheduleIds=' + ids).then(response => {
      if (response.data.ResultCode === 1) {
        dispatch({
          type: "GET_USER_DETAILS_SUCCESS",
          payload: userDetails(response.data.Data)
        })
        resolve()
      } else {
        reject()
      }
    }).catch(err => {
      reject()
    })
  })

export const removeSchedulesFromUser = (token, schedules, userId) => (dispatch) =>
  new Promise(function (resolve, reject) {

    var ids = ''
    for (var i = 0; i < schedules.length; i++) {
      if (ids === '') ids += schedules[i].id
      else ids += ',' + schedules[i].id
    }

    axios.defaults.headers.common = { 'Authorization': 'Bearer ' + token }
    axios.get('api/julian/removeSchedules?lockerUserId=' + userId + '&scheduleIds=' + ids).then(response => {
      console.log(response)
      if (response.data.ResultCode === 1) {
        dispatch({
          type: "GET_USER_DETAILS_SUCCESS",
          payload: userDetails(response.data.Data)
        })
        resolve()
      } else {
        reject()
      }
    }).catch(err => {
      reject()
    })
  })
